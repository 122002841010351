/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import { FaCheck, FaUserCheck, FaTimes } from "react-icons/fa";
import { Row, Col, Modal, Button, FormControl, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import api from "../../services/api";
import { signOut } from "../../store/modules/auth/actions";
import history from "../../services/history";
import RequestContractManagment from "../../components/RequestContractManagment";
import ChangeTenantBaseStatus from "./Components/TenantBaseStatus";
import { Backr } from "./styles";

export default function Comercial() {
  const [clinics, setClinics] = useState([]);
  const [credits, setCredits] = useState();

  const [show, setShow] = useState(false);

  const dispatch = useDispatch();
  const role = useSelector((state) => state.auth.role);

  useEffect(() => {
    async function loadClinics() {
      const response = await api.get(`/clinicas`);
      setClinics(response.data);
    }
    loadClinics();
  }, []);

  // Pegar os créditos de uma clínica
  useEffect(() => {
    async function loadCredits() {
      const response = await api.get(`/all_credits`).catch((error) => {
        try {
          if (error.response.status === 401) {
            toast.error("Você precisa fazer login");
            dispatch(signOut());
            history.push("/cs");
          }
        } catch {
          toast.error("Você precisa fazer login");
          dispatch(signOut());
          history.push("/cs");
        }
      });
      setCredits(response.data);
    }
    loadCredits();
    // por causa do dispatch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Abrir modal de adicionar créditos
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const {
    register: registerCredits,
    handleSubmit: handleSubmitCredits,
    getValues: getValuesCredits,
  } = useForm();

  const onSubmitCredits = (data) => {
    async function request() {
      const send = await api
        .put(`/credits`, data)
        .then((response) => {
          toast.success(response.data.message);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
      return send;
    }
    request();
    setShow(false);
  };

  // Seleção de clínica para poder atualizar os créditos
  const handleInputChange = (e) => {
    try {
      const { value } = e.target;

      const clinica = credits.filter((x) => x.id === parseInt(value, 10));
      document.getElementById("credits").value = clinica[0].creditos;
    } catch {
      // eslint-disable-next-line no-console
      console.log();
    }
  };

  function selectClinic(calledFunction) {
    return (
      <div style={{ maxWidth: "50%", marginLeft: "5px" }}>
        <FormControl
          type="text"
          name="clinicId"
          label="clinicId"
          {...calledFunction("clinicId", { required: true })}
          list="clinicId"
          onChange={handleInputChange}
        />
        <datalist id="clinicId">
          {clinics.map((clinicas) => (
            <option
              key={clinicas.id}
              id={clinicas.id}
              value={`${clinicas.id} - ${clinicas.clinica}`}
            />
          ))}
        </datalist>
      </div>
    );
  }

  // Achar o nome da clinica para atualizar os crédtios
  function nome() {
    const clinica = clinics.filter(
      (x) => x.id === parseInt(getValuesCredits().clinicId, 10)
    );
    if (!clinica[0]) {
      return "";
    }
    return `${clinica[0].id} - ${clinica[0].clinica}`;
  }

  function creditsForCustomerManagment() {
    if (role === "MASTER" || role === "SALES") {
      return (
        <Backr>
          <h1>
            <FaUserCheck color="#333" />
            Gestão de Créditos
          </h1>

          <Form onSubmit={handleShow}>
            <Row>
              {selectClinic(registerCredits)}
              <Col xs={2}>
                <FormControl
                  required
                  id="credits"
                  type="number"
                  min="0"
                  {...registerCredits("amount", { required: true })}
                />
              </Col>
              <Col>
                <Button type="submit">
                  <FaCheck /> Atualizar créditos
                </Button>
              </Col>
            </Row>
          </Form>
        </Backr>
      );
    }
    return <></>;
  }

  return (
    <>
      {/* Ativar/desativar base */}
      {ChangeTenantBaseStatus(role)}

      {/* Inserção de créditos */}
      {creditsForCustomerManagment()}

      {/* Gestão de solicitação de contratos */}
      {RequestContractManagment(role)}

      {/* Modal confirmação de inserção de créditos */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Atualização de créditos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Deseja atualizar com <strong> {getValuesCredits().amount}</strong>{" "}
          créditos a clínica
          <strong> {nome()}</strong>
        </Modal.Body>
        <Modal.Footer>
          <Col className="d-flex justify-content-start">
            <Button variant="outline-danger" onClick={handleClose}>
              <FaTimes /> Cancelar
            </Button>
          </Col>
          <Col className="d-flex justify-constent-end">
            <Button
              variant="success"
              onClick={handleSubmitCredits(onSubmitCredits)}
            >
              <FaCheck /> Confirmar
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>

      <footer>
        <p>.</p>
      </footer>
    </>
  );
}
